<template>
  <div>
    <div class="row" id="printJS-form">
      <div
        class="col-md-12 wrapprint"
        v-for="(qrcode, index) in popupParam.selectData"
        :key="index"
      >
        <div :class="qrClass">
          <vue-qrcode
            :value="serverName + '/sop/mim/inspection/equipmentQrSelect?equipmentCd=' + qrcode.equipmentCd"
            :options="{ width: 180, errorCorrectionLevel: 'L' }"
            class="canvas"
          >
          </vue-qrcode>
          <span class="equipmentArea">
            <b>
              {{
                '(' + qrcode.equipmentNo
                  ? qrcode.equipmentNo
                  : '' + ')'
              }}
            </b>
            <br>
            <b>
              {{ qrcode.equipmentName }}
            </b>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import printJs from 'print-js';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  /** attributes: name, components, props, data **/
  name: 'equipment-qr',
  components: {
    VueQrcode,
  },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          selectData: []
        }
      },
    },
  },
  data() {
    return {
      redirectUrl: '',
      component: null,
      tabIndex: 0,
      printItem: [],
      selectedItem: 'zt410',
      serverName: '',
      prompt: false, 
    };
  },
  computed: {
    qrStyle() {
      return this.selectedItem === 'zt420'
        ? 'margin-bottom: 152px !important'
        : null;
    },
    qrClass() {
      return this.selectedItem === 'zt420' ? 'wrap zt420' : 'wrap';
    },
  },
  watch: {},
  /** Vue lifecycle: created, mounted, destroyed, etc **/

  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {},
  beforeDestroy() {},
  /** methods **/
  methods: {
    /** 초기화 관련 함수 **/
    init() {
      this.redirectUrl = selectConfig.mdm.equipment.qrUrl.url;
      this.printItem = [
        {
          code: 'zt410',
          codeName: 'ZT-410',
        },
        {
          code: 'zt420',
          codeName: 'ZT-420',
        },
      ];
      this.serverUrl();
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    closePopup() {
      this.$emit('closePopup');
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '25px',
        targetStyles: ['*'],
      });
    },
  },
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 40px 20px 0px;
  width: 350px;
  height: 250px;
  box-sizing: border-box;
  margin-bottom: 161px;
}
.wrapprint:last-child > .wrap {
  margin-bottom: 0px !important;
}
.equipmentArea {
  padding: 50px 0px 0px 0px;
  display: flex;
  font-size: 1.3em;
  justify-content: center;
  flex-wrap: wrap;
  max-width:130px;
}
.contentArea {
  width: 100%;
  /* height:20px; */
  font-size: 1.5em;
  line-height: 50px;
  display: inline;
  padding-right: 10px;
}
@media print {
  .contentArea {
    width: 100%;
    /* height:20px; */
    font-size: 1.5em;
    display: inline;
    padding-top: 30px;
  }
}
.modelno {
  float: left;
  margin-right: 20px;
}
.zt420 {
  margin-bottom: 152px;
}
/* .listArea {
  max-height: 318px;
  overflow-y: auto;
} */
</style>
